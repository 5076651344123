@import url("./stylesheet.css");

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
    html {
        font-family: Helvetica Neue ost, system-ui, sans-serif;
        font-weight: normal;
        font-style: normal;
    }
    body
    {
        scrollbar-width: thin;
    }
    body::-webkit-scrollbar {
        width: 5px !important;
    }
    body{
        @apply bg-gray_50
    }
    *::-webkit-scrollbar {
        width: 20px !important;
    }
    *::-webkit-scrollbar-track {
        @apply bg-gray_100 rounded;
    }
    *::-webkit-scrollbar-thumb {
        @apply bg-gray_300 rounded border-none;
    }

    .scrollbar-small
    {
        scrollbar-width: thin;
    }
    .scrollbar-small::-webkit-scrollbar {
        width: 15px !important;
    }
}
input, input:focus {
    outline: none;
}
textarea, textarea:focus {
    outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
}
button{
    outline: 0;
}
.notification_container{
    position: fixed;
    right: 16px;
    top: 24px;
    z-index: 100;
}
.radio-styled {
    position: relative;
    top: 2px;
    margin-right: 0.5rem;
}
.radio:checked + .radio-styled {
    border-width: 5px !important;
    border-color: #60A5FA !important;
}
.ant-table-thead tr .ant-table-cell{
    border-bottom: 1px solid #E5E7EB !important;
    font-size: 14px;
    line-height: 150%;
    font-weight: 500;
    color: #9CA3AF !important;
}
.ant-table-row-level-1 .ant-table-cell{
    background-color: #EFF6FF !important;
}
.table-row__transactions td {
    border-bottom: unset !important;
    font-size: 16px;
}
.shepherd-modal-overlay-container {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    fill: #1F2937;
    opacity: 0.4;
}
.shepherd-arrow {
    height: 20px;
    width: 20px;
    position: absolute;
    top: -10px;
    background-color: #fff;
    transform: rotate(45deg);
}
.shepherd-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
    color: #4B5563;
}
.shepherd-cancel-icon {
    color: #D1D5DB;
    font-size: 32px;
    font-weight: 400;
    line-height: 0.5;
}
.shepherd-footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}

@layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }
}