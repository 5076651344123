
@font-face {
    font-family: 'Helvetica Neue ost';
    src: local('Helvetica Neue'), local('HelveticaNeue'),
    url('./font/helvetica_neue_ost.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue ost';
    src: local('Helvetica Neue'), local('HelveticaNeue'),
    url('./font/helvetica_neue_bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
